.container {
  @include padding-h(20px);

  max-width: 100%;
  margin: 0 auto;

  @include breakpoint(md) {
    @include padding-h(45px);

    max-width: 1200px;
  }

  &--lg {
    @include breakpoint(lg) {
      @include padding-h(56px);

      max-width: 1440px;
    }
  }

  &--content {
    padding-top: 42px;

    @include breakpoint(md) {
      padding-top: 82px;
    }
  }
}
