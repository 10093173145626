.info-section {
  $component: &;

  position: relative;
  padding: 30px;
  border: 1px solid $light-blue;
  background-color: $white;
  overflow: hidden;

  @include breakpoint(md) {
    @include z-index(background);

    width: 100%;
    padding: 40px;
  }

  &--not-relative {
    position: unset;
  }

  &--background {
    background-color: $light-blue;

    &::before,
    &::after {
      background-color: $light-blue;
    }
  }

  &--right {
    @include breakpoint(lg) {
      padding-right: 0;
      padding-left: 100px;

      &::before {
        content: none;
      }

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: calc((100vw - (100vw - (45px * 2) )) / 2);
        left: 100%;
        top: 0;

        @include breakpoint(xl) {
          width: calc((100vw - 1200px) / 2 + 45px);
        }
      }
    }
  }

  &--full-width {
    @include breakpoint(md-max) {
      @include margin-h(-20px);
    }
  }

  &--overlap-left {
    @include z-index(background);

    padding-bottom: 280px;
    margin-bottom: -235px;

    @include breakpoint(xl) {
      padding-bottom: 0;
      margin-bottom: 0;
      padding-left: 280px;
      margin-left: -180px;
    }
  }

  &--p-top {
    #{$component}__head {
      @include breakpoint(md) {
        padding-top: 100px;
      }

      @include breakpoint(lg) {
        padding-top: 180px;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    @include breakpoint(md) {
      align-items: center;
    }
  }

  &__head-icon {
    margin-right: 15px;
  }

  &__head-headline {
    margin-bottom: 0;
  }

  &__icon {
    position: absolute;
    bottom: -1rem;
    right: -0.5rem;
    width: 40%;
    height: auto;
    opacity: 0.2;
  }
}
