.footer {
  width: 100%;
  background-color: $white;
  justify-content: normal;

  @include breakpoint(md) {
    height: 80px;
  }

  &__nav {
    flex-direction: row;

    @include breakpoint(md-max) {
      @include padding-v(20px);

      justify-content: center;
      width: 100%;
    }
  }

  &__nav-item {
    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }

  &__contact {
    margin: 0 -20px;
    width: calc(100% + (20px * 2));

    @include breakpoint(md) {
      margin-right: 0;
      margin-left: auto;
      width: auto;
    }

    @include breakpoint(md-max) {
      @include padding-v(32px);

      justify-content: center;
      padding-bottom: 32px;
      border-bottom: 1px solid $light-grey;
    }
  }

  &__contact-head {
    &:not(:last-of-type) {
      margin-bottom: 8px;

      @include breakpoint(md) {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }
  }

  &__contact-head-icon {
    margin-right: 8px;
  }
}
