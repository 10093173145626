.usp {
  justify-content: space-evenly;

  @include breakpoint(md) {
    align-items: flex-start;
  }

  &__item {
    text-align: center;
    max-width: 400px;
    margin-bottom: 18px;

    @include breakpoint(md) {
      max-width: 250px;
      margin-bottom: 24px;
    }
  }

  &__icon {
    margin-bottom: 16px;
    max-width: 64px;

    @include breakpoint(md) {
      max-width: inherit;
    }
  }
}
