/// Nav-Element verstecken
///
/// @name nav-hidden
///
/// @example scss
///   @import "helpers/nav-hidden-visible";
///   ul.example {
///     @include nav-hidden;
///   }
///
/// @group mixins-helpers
@mixin element-hidden {
  opacity: 0;
  pointer-events: none;
  height: 0;
  padding: 0;
}

/// Nav-Element wieder anzeigen
///
/// @name nav-visible
///
/// @example scss
///   @import "helpers/nav-hidden-visible";
///   ul.example {
///     @include nav-visible(8px);
///   }
///
/// @group mixins-helpers
@mixin element-visible($padding: 0, $margin: 0) {
  opacity: 1;
  pointer-events: auto;
  height: auto;
  padding: #{$padding};
}
