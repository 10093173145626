.about-us {
  @include breakpoint(xl) {
    align-items: stretch;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__info-text {
    &--margin-bottom {
      margin-bottom: 64px;
    }
  }
}
