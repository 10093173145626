.job {
  background-color: $white;
  box-shadow: 0 8px 24px 0 $shadow-grey;
  padding: 16px 24px 16px 20px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__head {
    padding-bottom: 28px;
    border-bottom: 1px solid $light-grey;
  }

  &__body {
    margin-top: 32px;
    max-width: 800px;

    p {
      margin-bottom: 40px;
    }

    ul {
      margin-top: -24px;
      margin-bottom: 40px;
    }
  }

  &__logo {
    display: none;
    max-width: 80px;
    padding-right: 20px;

    @include breakpoint(lg) {
      display: block;
    }
  }

  &__arrow {
    margin-left: auto;
  }

  &__content {
    @include breakpoint(lg) {
      padding-left: 24px;
      border-left: 1px solid $light-grey;
    }
  }

  &__location-text {
    margin-right: 24px;
  }

  &__location-icon {
    margin-right: 8px;
  }
}
