.floating-box {
  margin-top: -2.5rem;
  display: flex;
  flex-direction: column;
  background-size: 100%;
  padding: 30px;
  text-align: left;
  border: 1px solid $light-blue;
  background-color: $white;

  @include breakpoint(md) {
    @include z-index(floating);

    text-align: center;
    padding: 80px 42px;
  }

  @include breakpoint(lg) {
    padding: 80px 172px;
  }

  &--blue {
    background-color: $blue;
    border: 1px solid $blue;
    background-image: url("../../images/illu-speak-bubble.svg");
    background-position: right bottom 1rem;
    background-repeat: no-repeat;
    background-size: 50%;

    @include breakpoint(md) {
      background-position: right center;
      background-size: 25%;
    }

    &,
    p {
      color: $white;
    }
  }

  &--nm-bottom {
    padding-bottom: 110px;
    margin-bottom: -90px;
  }

  .headline {
    margin-bottom: 16px;
    color: $blue;
  }

  &--blue .headline {
    color: $white;
  }

  .flex-wrapper {
    margin-top: 40px;

    .button {
      @include breakpoint(md-max) {
        width: 100%;
      }

      &:first-of-type {
        margin-bottom: 24px;

        @include breakpoint(md) {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }

    .link {
      &:first-of-type {
        margin-bottom: 24px;

        @include breakpoint(md) {
          margin-right: 24px;
          margin-bottom: 0;
        }
      }
    }
  }
}
