// Elemente mit dem gleichen Margin auf der vertikalen (margin-v) oder horizontalen (margin-h) Achse versehen
// Kann mit beliebigen Einheiten verwendet werden, auf Zahlen ohne Einheit wird sizing() angewendet
//
// .example {
//   @include margin-h(1);
// }
// -> 8px horizontales Margin
@mixin margin-v($value) {
  $value: unitless-sizing($value);

  margin-top: $value;
  margin-bottom: $value;
}

@mixin margin-h($value) {
  $value: unitless-sizing($value);

  margin-right: $value;
  margin-left: $value;
}
