.flex-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  @include breakpoint(md) {
    flex-direction: row;
  }

  &__image {
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--baseline {
    align-items: baseline;
  }

  &--flex-start {
    align-items: flex-start;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--wrap-reverse {
    @include breakpoint(xl-max) {
      flex-wrap: wrap-reverse;
      flex-direction: row;
    }
  }

  &--m-bottom {
    margin-bottom: 48px;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--column {
    flex-direction: column;
    justify-content: unset;
  }

  &--md-column {
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }

  &--xl-column {
    flex-direction: column;

    @include breakpoint(xl) {
      flex-direction: row;
    }
  }

  &--centered {
    justify-content: center;
  }

  &--row {
    flex-direction: row;
  }

  &--not-center {
    align-items: unset;
  }

  &--md-row {
    @include breakpoint(md) {
      flex-direction: row;
    }
  }
}
