@use "../2_tools/helpers/rem";

.partner-bar {
  $component: &;

  border-bottom: 1px solid $light-grey;
  margin-bottom: 40px;

  @include breakpoint(md) {
    margin-bottom: 80px;
  }

  &--only-partners {
    border: none;
    margin-bottom: 45px;

    #{$component}__image {
      @include breakpoint(md) {
        max-width: 118px;
      }
    }
  }

  &__headline {
    white-space: pre;
    text-align: center;
    font-size: rem.rem(14px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(md) {
      font-size: rem.rem(24px);
    }

    &::after,
    &::before {
      content: "";
      display: inline-block;
      border-top: solid $light-grey 1px;
      width: calc(50% - (106px / 2));

      @include breakpoint(md) {
        width: calc(50% - (232px / 2));
      }
    }

    &::before {
      margin-right: 8px;

      @include breakpoint(md) {
        margin-right: 40px;
      }
    }

    &::after {
      margin-left: 8px;

      @include breakpoint(md) {
        margin-left: 40px;
      }
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 12px 0;
    text-align: center;

    @include breakpoint(sm) {
      flex-wrap: initial;
      margin-bottom: 20px;
    }

    @include breakpoint(md) {
      margin: 20px 0 32px;
      justify-content: space-evenly;
      align-items: flex-end;

      &--vertical-centered {
        align-items: center;
      }
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 30px;
    width: calc(100% * (1 / 2) - 20px);

    @include breakpoint(sm) {
      width: auto;
      margin-bottom: 0;
    }

    &:hover {
      #{$component}__image {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }

  &__image {
    transition: $animation-default filter, $animation-default opacity;
    filter: grayscale(1);
    opacity: 0.5;
    width: 100%;
    max-width: 140px;

    @include breakpoint(sm) {
      width: 85%;
    }

    @include breakpoint(lg) {
      width: 100%;
    }
  }
}
