.grid {
  display: grid;
  grid-gap: 2rem;
  align-items: stretch;

  &--50-50 {
    grid-template-columns: 1fr;

    @include breakpoint(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
