// Elemente mit dem gleichen Padding auf der vertikalen (padding-v) oder horizontalen (padding-h) Achse versehen
// Kann mit beliebigen Einheiten verwendet werden, auf Zahlen ohne Einheit wird sizing() angewendet
//
// .example {
//   @include padding-v(1);
// }
// -> 8px vertikales Padding
@mixin padding-v($value) {
  $value: unitless-sizing($value);

  padding-top: $value;
  padding-bottom: $value;
}

@mixin padding-h($value) {
  $value: unitless-sizing($value);

  padding-right: $value;
  padding-left: $value;
}
