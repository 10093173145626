@use "../2_tools/helpers/rem";

.rocket-teaser {
  position: relative;
  max-width: 484px;

  @include breakpoint(xl) {
    margin-left: 4.5%;
  }

  &__info {
    font-size: rem.rem(24px);
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 68%;
    max-width: 130px;
    color: $dark-grey;
    line-height: 1;

    @include breakpoint(lg) {
      font-size: rem.rem(40px);
      max-width: inherit;
    }

    &--left {
      left: 32px;
    }

    &--right {
      right: 24px;
    }

    &--centered {
      left: 50%;
      width: 150px;
      transform: translateX(-50%);
    }

    &--centered-top {
      width: 70px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__light-text {
    font-size: rem.rem(8px);

    @include breakpoint(xl) {
      font-size: rem.rem(12px);
    }
  }

  &__image {
    max-width: 100%;
    margin-top: 26px;

    @include breakpoint(xl) {
      max-width: inherit;
      margin-top: 0;
    }
  }
}
