.images-box {
  width: 100%;

  &__wrapper {
    height: auto;
    width: 100%;
    position: relative;
    justify-content: space-between;

    @include breakpoint(lg) {
      max-width: 50%;
    }
  }

  &__image {
    max-width: 50%;
    width: calc(50% - 16px);
    margin-bottom: 32px;

    @include breakpoint(lg) {
      margin-bottom: 18px;

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }

    &--large {
      height: auto;
      margin-right: 16px;

      @include breakpoint(lg-max) {
        margin-right: 0;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
