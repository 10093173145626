.text {
  max-width: 814px;
  margin: 0 auto;
  text-align: center;

  &--md-centered {
    text-align: left;

    @include breakpoint(md) {
      text-align: center;
    }
  }

  &--align-left {
    text-align: left;
  }

  &--m-bottom {
    margin-bottom: 48px;
  }
}
