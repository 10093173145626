.portfolio {
  $component: &;

  &__wrapper {
    @include margin-h(-6px);

    display: flex;
    flex-wrap: wrap;
    max-width: 820px;
    margin: 0 auto;
  }

  &__item {
    margin: 6px;
    width: 100%;
    max-height: 170px;

    @include breakpoint(xs) {
      width: calc(50% - 12px);
    }

    @include breakpoint(sm) {
      width: calc(25% - 12px);
    }
  }

  &__item-container {
    padding: 24px;
    border: 1px solid $light-grey;
    transition: $animation-duration-default transform;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;

      #{$component}__item-image {
        filter: none;
      }
    }
  }

  &__item-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    filter: grayscale(100%);
    width: 80px;
    height: 80px;
    margin: auto;

    @include breakpoint(lg) {
      width: 120px;
      height: 120px;
    }
  }

  &__text {
    margin-bottom: 24px;

    @include breakpoint(lg) {
      margin-bottom: 32px;
    }
  }

  .headline {
    margin-bottom: 16px;
  }
}
