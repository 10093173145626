$line-height-paragraph: 1.6;
$font-display: swap;

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-display: $font-display;
  font-weight: 400;
  src:
    local(""),
    url("../../fonts/roboto-condensed-v25-latin-regular.woff2") format("woff2"),
    url("../../fonts/roboto-condensed-v25-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("../../fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    url("../../fonts/roboto-v30-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("../../fonts/roboto-v30-latin-700.woff2") format("woff2"),
    url("../../fonts/roboto-v30-latin-700.woff") format("woff");
}

$base-font: "Roboto", sans-serif;
$headline-font: "Roboto Condensed", sans-serif;
