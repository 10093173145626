.image-describer {
  @include breakpoint(lg) {
    flex-wrap: nowrap;
  }

  &__text {
    padding-bottom: 20px;

    @include breakpoint(md) {
      padding-bottom: 0;
      padding-right: 20px;
    }
  }

  .flex-wrapper--row-reverse &__text {
    @include breakpoint(md) {
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 0;
    }
  }
}
