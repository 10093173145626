.component {
  margin-bottom: 50px;
  position: relative;

  @include breakpoint(md) {
    margin-bottom: 100px;
  }

  &--grid {
    margin-bottom: 2rem;
  }

  &--md {
    margin-bottom: 60px;

    @include breakpoint(md) {
      margin-bottom: 120px;
    }
  }

  &--lg {
    margin-bottom: 70px;

    @include breakpoint(md) {
      margin-bottom: 160px;
    }
  }
}
