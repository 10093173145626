@use "../2_tools/helpers/rem";

li {
  @include list-reset;

  position: relative;
  font-size: rem.rem(16px);
  color: $dark-grey;
  padding: 0 0 0 15px;
  line-height: 28px;
  margin: 0 0 15px 0;

  &::before {
    content: "";
    position: absolute;
    top: 11px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: $blue;
    border-radius: 100%;
  }
}
