h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headline-font;
}

h1 {
  margin-bottom: 30px;
}

h2 {
  margin-bottom: 20px;
  margin-top: 30px;
}

h3 {
  margin-bottom: 10px;
  margin-top: 20px;
}

h4 {
  margin-bottom: 10px;
  margin-top: 20px;
}
