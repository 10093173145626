@use "../2_tools/helpers/rem";

.button {
  @include z-index(body);

  padding: 15px 32px;
  text-align: center;
  appearance: none;
  font-size: rem.rem(14px);
  font-weight: 700;
  color: $middle-blue;
  border: 1px solid $green;
  background-color: $green;
  transition: $animation-default transform;

  @include breakpoint(md) {
    font-size: rem.rem(16px);
  }

  &:hover {
    transform: scale(1.1);
  }

  &--reversed {
    background-color: transparent;
    border-color: $white;
  }

  &--white {
    color: $black;
    box-shadow: none;
    border-color: $light-grey;
    background-color: $white;
  }
}
