.hero {
  background-color: $blue;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80vh;
    padding-bottom: 50vh;
    padding-top: 75px;

    @include breakpoint(md) {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    @include breakpoint(md) {
      justify-content: space-between;
      flex-direction: row;
    }

    @include breakpoint(maxi) {
      min-height: 0;
      position: relative;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    @include breakpoint(md) {
      @include z-index(floating);

      align-items: baseline;
      position: relative;
      text-align: left;
      width: 40%;
    }

    @include breakpoint(lg) {
      margin-right: 40px;
    }
  }

  &__info-headline {
    margin-bottom: 32px;
    color: $white;
    font-weight: 400;
  }

  &__info-desc {
    margin-bottom: 40px;
    color: $white;
    font-size: 1rem;
    line-height: 1.6;
  }

  &__image {
    position: absolute;
    bottom: 50px;
    right: 0;
    max-width: 100vw;

    @include breakpoint(md) {
      bottom: 50%;
      transform: translateY(50%);
      max-width: 50vw;
    }

    @include breakpoint(maxi) {
      max-width: 50%;
    }

    img {
      max-height: 45vh;
      width: auto;

      @include breakpoint(md) {
        max-width: 100%;
        max-height: none;
        height: auto;
      }
    }
  }
}
