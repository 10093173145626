@import "../../1_settings/zindex-config";

/// Funktion um auf die Map mit Z-Indizes zuzugreifen.
/// Die Map selbst, wird mit dieser Funktion in der Main.css geladen
/// und muss nicht separat importiert werden.
///
/// @name Z-Index
///
/// @example scss
///   .example {
///     z-index: z-index(navigation);
///     // z-index: 100;
///   }
///
/// @group functions-general
@function z-index($key) {
  @return map-get($z-index, $key);
}

/// Dieses Mixin ergänzt die gleichnamige Funktion und vereinfacht den Zugriff auf die Z-Index-Map,
/// bzw. die Verwendung der Funktion.
///
/// @name Z-Index Mixin
///
/// @example scss
///   @import "general/zindex";
///   .example {
///     @include z-index(navigation);
///     // z-index: 100
///   }
///
/// @group mixins-general
@mixin z-index($key) {
  z-index: z-index($key);
}
