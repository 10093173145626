@use "../2_tools/helpers/rem";

.mobile-header {
  display: none;
  position: fixed;
  top: 48px;
  width: 100%;
  left: 0;
  background-color: $blue;
  padding: 34px 24px 0;
  bottom: 0;
  justify-content: space-between;

  &.active {
    display: flex;
  }

  &__contact {
    width: 100%;
    border-top: 1px solid $light-grey;
    padding: 24px 20px;
  }

  .link {
    font-family: $headline-font;
    font-size: rem.rem(18px);
    color: $white;

    &:not(:last-of-type) {
      margin-bottom: 32px;
    }
  }

  .language-switcher {
    margin-top: 24px;
    margin-left: -8px;
  }
}
