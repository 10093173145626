@use "../2_tools/helpers/rem";

.headline {
  font-weight: 400;
  font-size: rem.rem(50px);
  color: $blue;
  margin-top: 0;

  &--h1 {
    font-size: rem.rem(32px);
    margin-bottom: 28px;
    text-transform: uppercase;

    @include breakpoint(md) {
      font-size: rem.rem(36px);
      line-height: 42px;
    }

    @include breakpoint(xl) {
      font-size: rem.rem(50px);
      line-height: 60px;
    }
  }

  &--h2 {
    font-size: rem.rem(22px);
    margin-bottom: 16px;

    @include breakpoint(md) {
      font-size: rem.rem(40px);
      line-height: 46px;
      margin-bottom: 30px;
    }
  }

  &--h3 {
    font-size: rem.rem(18px);
    margin-bottom: 8px;

    @include breakpoint(md) {
      margin-bottom: 30px;
      font-size: rem.rem(30px);
      line-height: 34px;
    }
  }

  &--h4 {
    font-size: rem.rem(18px);
    margin-bottom: 2px;

    @include breakpoint(md) {
      margin-bottom: 8px;
      font-size: rem.rem(24px);
      line-height: 32px;
    }
  }

  &--h5 {
    font-size: rem.rem(16px);
    margin-bottom: 2px;

    @include breakpoint(md) {
      margin-bottom: 8px;
      font-size: rem.rem(20px);
      line-height: 20px;
    }
  }

  &--m-top {
    margin-top: 28px;
  }

  &--centered {
    text-align: center;
  }

  &--highlighted {
    color: $blue;
  }

  &--highlighted-green {
    color: $green;
  }

  &--lg-left {
    @include breakpoint(md) {
      text-align: left;
    }
  }

  &--md-centered {
    @include breakpoint(md) {
      text-align: center;
    }
  }

  &--big {
    font-size: rem.rem(48px);
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: rem.rem(64px);
      line-height: 64px;
    }
  }

  &--xl {
    font-size: rem.rem(72px);
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: rem.rem(122px);
      line-height: 64px;
    }
  }

  &--grey {
    color: $light-grey;
  }
}
