.trust-stats {
  &__item {
    &:not(:last-of-type) {
      position: relative;
      width: 190px;
      margin-bottom: 32px;
      border-bottom: 1px solid $light-grey;

      @include breakpoint(md) {
        margin-bottom: 0;
        border-bottom: none;
      }

      &::after {
        content: none;
        position: absolute;

        @include breakpoint(md) {
          margin-left: 32px;
          content: "";
          top: 0;
          left: 100%;
          display: inline-block;
          height: 100px;
          width: 1px;
          background-color: $light-grey;
          transform: rotate(10deg);
        }

        @include breakpoint(lg) {
          margin-left: 64px;
        }
      }
    }
  }
}
