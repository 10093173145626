.portfolio-modal {
  @include z-index(modal);

  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($dark-grey, 0.8);
  overflow: scroll;

  &.active {
    display: block;
  }

  &__container {
    position: absolute;
    background-color: $white;
    width: calc(100% - 64px);
    top: 32px;
    margin: 32px;

    @include breakpoint(md) {
      width: 568px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

  &__image {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__text {
    margin-bottom: 18px;

    @include breakpoint(lg) {
      margin-bottom: 28px;
    }
  }

  &__content {
    padding: 24px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    @include breakpoint(xl) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__info-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    max-width: 100%;
    word-break: break-word;
    word-wrap: break-word;

    @include breakpoint(lg) {
      margin-bottom: 0;
      max-width: 120px;
    }
  }

  &__info-value {
    text-decoration: none;
    color: $dark-grey;
    line-height: 28px;
    font-size: 12px;

    @include breakpoint(md) {
      font-size: 16px;
    }
  }

  &__info-title {
    white-space: nowrap;
    color: $blue;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 18px;

    @include breakpoint(lg) {
      width: 22px;
      height: 22px;
    }
  }

  &__close-image {
    width: 100%;
    height: 100%;
  }
}
