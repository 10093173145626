.link {
  color: $dark-blue;
  font-size: 14px;

  &--underline {
    text-decoration: underline;
  }

  &--blue {
    color: $blue;
  }

  &--white {
    color: $white;
  }

  &--with-icon {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    max-width: 32px;
    max-height: 32px;
    margin-right: 8px;

    &--m-left {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
