.image {
  width: 100%;

  &--50 {
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }
  }
}
