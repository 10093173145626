$white: #ffffff;
$black: #000000;
$blue: #009ee0;
$middle-blue: #228fbf;
$dark-blue: #232643;
$dark-grey: #66676f;
$green: #cdff00;
$shadow-grey: rgba(0, 0, 0, 0.16);
$light-grey: #cccccc;
$light-blue: #cfd8dd;
