.buttons {
  justify-content: normal;

  .button {
    &:first-of-type {
      margin-right: 0;
      margin-bottom: 16px;

      @include breakpoint(md) {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }
}
