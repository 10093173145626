@use "../2_tools/helpers/rem";

.header {
  @include z-index(navigation);

  background-color: $blue;
  position: fixed;
  top: 0;
  width: 100%;

  &__container {
    height: 48px;
    display: flex;
    align-items: center;

    @include breakpoint(md) {
      height: 100px;
    }
  }

  &__logo {
    width: auto;
    height: 40px;
    display: block;

    @include breakpoint(md) {
      height: 70px;
    }

    @include breakpoint(lg) {
      max-width: inherit;
    }
  }

  &__navigation {
    margin-left: auto;
    margin-right: 45px;
    display: none;
    position: relative;

    @include breakpoint(md) {
      display: block;
    }

    .link {
      color: $white;
      font-family: $headline-font;

      @include breakpoint(lg) {
        font-size: rem.rem(20px);
      }

      &:hover {
        color: $green;
      }

      &:not(:last-of-type) {
        margin-right: 20px;

        @include breakpoint(lg) {
          margin-right: 40px;
        }
      }
    }

    .language-switcher {
      position: absolute;
      top: 0;

      @include breakpoint(md) {
        right: -60px;
      }

      @include breakpoint(lg) {
        right: -80px;
      }
    }
  }

  &__mobile-navigation {
    margin-left: auto;

    @include breakpoint(md) {
      display: none;
    }
  }
}
