.language-switcher {
  $component: &;

  &:hover,
  &:focus,
  &:focus-within {
    #{$component}__list-link {
      @include element-visible(8px);

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    @include list-reset;
    @include z-index(languageSwitcher);

    border-radius: 4px;

    #{$component}__list-item {
      @include list-reset;

      &::before {
        content: none;
      }
    }
  }

  &__list-link {
    @include element-hidden;

    align-items: center;
    cursor: pointer;
    display: block;
    transition: opacity $animation-default, height $animation-default, padding $animation-default;
    width: 100%;
    pointer-events: none;

    &.selected {
      @include element-visible(8px);

      display: flex;
      pointer-events: auto;

      &::after {
        background-image: url("../../images/arrow-down-white.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        content: " ";
        display: inline-block;
        height: 10px;
        width: 10px;
        transition: transform $animation-default;
        margin-left: 6px;
      }
    }
  }

  &__flag {
    margin-right: 8px;
  }
}
